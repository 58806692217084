import { useMemo, useCallback } from 'react';
import { Address as BaseAddress } from '@commercetools/frontend-domain-types/account/Address';
import { AccessToken, IDToken, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import useSWR, { mutate } from 'swr';
import { sdk } from 'sdk';
import { Account, Address } from 'types/account';
import { OktaConfigOptions, TokenClaims } from 'types/okta';
import { revalidateOptions } from 'frontastic';
import { GetAccountResult, RegisterAccount, UpdateAccount, UseAccountReturn } from './types';

const useAccount = (): UseAccountReturn => {
  const extensions = sdk.composableCommerce;

  const result = useSWR('/action/account/getAccount', extensions.account.getAccount, revalidateOptions);

  const revalidateAccountData = useCallback(() => {
    mutate('/action/account/getAccount');
  }, []);

  const handleLogin = async () => {
    await sdk.callAction({ actionName: 'cart/setAnonymousCartId' });
    const res = await sdk.callAction<OktaConfigOptions>({ actionName: 'auth/getConfiguration' });
    const { redirect, ...config } = res.isError ? ({} as OktaConfigOptions) : res.data;

    const oktaAuth = new OktaAuth(config);

    if (oktaAuth.isLoginRedirect()) {
      const { accessToken, idToken } = (await oktaAuth.token.parseFromUrl()).tokens;
      oktaAuth.tokenManager.setTokens({ accessToken, idToken });

      const claims: TokenClaims = {
        accessToken: (accessToken?.claims || {}) as UserClaims,
        idToken: (idToken?.claims || {}) as UserClaims,
      };

      await sdk.callAction({
        actionName: 'auth/setSessionToken',
        payload: { claims },
      });
      await sdk.callAction({ actionName: 'cart/mergeCarts' });
      // TODO(https://nikoninc.atlassian.net/browse/REPLATFORM-3464): handle inventory error messages
      await sdk.callAction({ actionName: 'cart/validateCartInventory' });

      window.location.replace(redirect || '/');
    }
  };

  const handleLogout = async () => {
    const res = await sdk.callAction<OktaConfigOptions>({ actionName: 'auth/getConfiguration' });

    const options = res.isError ? ({} as OktaConfigOptions) : res.data;

    window.location.replace(options.redirect || '/');
  };

  const data = useMemo(() => {
    if (result.isValidating) return { loggedIn: false, accountLoading: true };

    if (result.data?.isError) return { loggedIn: false, accountLoading: false, error: result.error };

    const account = (result.data?.data as GetAccountResult)?.account as Account;

    if (account?.accountId) return { account, loggedIn: true, accountLoading: false };

    return {
      loggedIn: false,
      account: undefined,
      accountLoading: false,
      error: result.error,
    };
  }, [result]);

  const shippingAddresses = useMemo(() => {
    if (!data.account) return [];

    return (data.account.addresses ?? []).filter((address) =>
      data.account.shippingAddressIds?.includes(address.id || ''),
    );
  }, [data.account]);

  const billingAddresses = useMemo(() => {
    if (!data.account) return [];

    return (data.account.addresses ?? []).filter((address) =>
      data.account.shippingAddressIds?.includes(address.id || ''),
    );
  }, [data.account]);

  const defaultShippingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.id === data.account.defaultShippingAddress);
  }, [data.account]);

  const defaultBillingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.id === data.account.defaultBillingAddress);
  }, [data.account]);

  const login = async () => {
    await sdk.callAction({ actionName: 'cart/setAnonymousCartId' });

    const res = await sdk.callAction<OktaConfigOptions>({
      actionName: 'auth/getConfiguration',
      query: { redirectUrl: window.location.href || '/' },
    });

    const oktaAuth = new OktaAuth(res.isError ? {} : res.data);

    return oktaAuth.signInWithRedirect();
  };

  const initiateAuthentication = async (redirectUrl = '') => {
    const returnUrl = redirectUrl !== '' ? redirectUrl : window?.frames?.top?.document.referrer;
    const res = await sdk.callAction<OktaConfigOptions>({
      actionName: 'auth/getConfiguration',
      query: { redirectUrl: returnUrl || '/' },
    });

    const oktaAuth = new OktaAuth(res.isError ? {} : res.data);

    return oktaAuth.signInWithRedirect();
  };

  const loginCT = async (email: string, password: string, remember?: boolean): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const payload = {
      email,
      password,
      remember,
    };

    const res = await extensions.account.login(payload);

    mutate('/action/account/getAccount');
    mutate('/action/cart/getCart');
    mutate('/action/wishlist/getWishlist');

    return res.isError ? ({} as Account) : res.data;
  };

  const logout = useCallback(async () => {
    const extensions = sdk.composableCommerce;
    const res = await sdk.callAction<OktaConfigOptions>({
      actionName: 'auth/getConfiguration',
      query: { redirectUrl: window.location.href },
    });

    const oktaAuth = new OktaAuth(res.isError ? {} : res.data);
    const accessToken = (await oktaAuth.tokenManager.get('accessToken')) as AccessToken;
    const idToken = (await oktaAuth.tokenManager.get('idToken')) as IDToken;

    await extensions.account.logout();

    await oktaAuth.signOut({
      revokeAccessToken: true,
      accessToken,
      idToken,
    });

    mutate('/action/account/getAccount');
    mutate('/action/cart/getCart');
    mutate('/action/wishlist/getWishlist');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const register = useCallback(async (account: RegisterAccount): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.register(account);

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const confirm = useCallback(async (token: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.confirm({ token });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const requestConfirmationEmail = useCallback(async (email: string, password: string): Promise<void> => {
    const extensions = sdk.composableCommerce;

    const payload = {
      email,
      password,
    };

    await extensions.account.requestConfirmationEmail(payload);
  }, []);

  const changePassword = useCallback(async (oldPassword: string, newPassword: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.changePassword({ oldPassword, newPassword });

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const requestPasswordReset = useCallback(async (email: string): Promise<void> => {
    const extensions = sdk.composableCommerce;

    const payload = {
      email,
    };

    await extensions.account.requestResetPassword(payload);
  }, []);

  const resetPassword = useCallback(async (token: string, newPassword: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.resetPassword({ token, newPassword });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const update = useCallback(async (account: UpdateAccount): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.updateAccount(account);

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const addIsSubscribedType = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const response = await extensions.account.getAccount();

    if (response.isError || response.data.loggedIn === false) return {} as Account;

    const res = await sdk.callAction<Account>({
      actionName: 'account/addIsSubscribedType',
      payload: { account: response.data.account },
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const updateSubscription = useCallback(async (isSubscribed: boolean) => {
    const extensions = sdk.composableCommerce;

    const response = await extensions.account.getAccount();

    if (response.isError || response.data.loggedIn === false) return {} as Account;

    const res = await sdk.callAction<Account>({
      actionName: 'account/updateSubscription',
      payload: { account: response.data.account, isSubscribed },
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const addAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.addAddress(address);

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const addShippingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const response = await extensions.account.getAccount();

    if (response.isError || response.data.loggedIn === false) return {} as Account;

    const res = await sdk.callAction<Account>({
      actionName: 'account/addShippingAddress',
      payload: { account: response.data.account, address },
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const addBillingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const response = await extensions.account.getAccount();

    if (response.isError || response.data.loggedIn === false) return {} as Account;

    const res = await sdk.callAction<Account>({
      actionName: 'account/addBillingAddress',
      payload: { account: response.data.account, address },
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const updateAddress = useCallback(async (address: Address): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.updateAddress(address);

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const removeAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.removeAddress({ addressId });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const setDefaultBillingAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.setDefaultBillingAddress({ addressId });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const setDefaultShippingAddress = useCallback(async (addressId: string): Promise<Account> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.account.setDefaultShippingAddress({ addressId });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  }, []);

  const verifyAddress = useCallback(
    async (address: BaseAddress): Promise<{ originalAddress: BaseAddress; suggestedAddress: BaseAddress }> => {
      const res = await sdk.callAction({
        actionName: 'cybersource/verifyAddress',
        payload: { address },
      });

      mutate('/action/cybersource/verifyAddress');

      if (res.isError) {
        throw new Error(res.error?.message);
      }
      return res.data as { originalAddress: BaseAddress; suggestedAddress: BaseAddress };
    },
    [],
  );

  return {
    ...data,
    shippingAddresses,
    billingAddresses,
    defaultShippingAddress,
    defaultBillingAddress,
    login,
    initiateAuthentication,
    loginCT,
    logout,
    handleLogin,
    handleLogout,
    register,
    confirm,
    requestConfirmationEmail,
    changePassword,
    requestPasswordReset,
    resetPassword,
    update,
    addIsSubscribedType,
    updateSubscription,
    addAddress,
    addBillingAddress,
    addShippingAddress,
    updateAddress,
    removeAddress,
    revalidateAccountData,
    setDefaultBillingAddress,
    setDefaultShippingAddress,
    verifyAddress,
  };
};

export default useAccount;
